Promise.all(
    [
        import("react-dom/client"),
        import("../pkg"),
    ])
    .then(([dom, wasm]) => Promise.all([dom, wasm, import('./app')]))
    .then(([{ createRoot }, wasm, { getApp }]) => {
        const root = createRoot(document.getElementById('root'));
        root.render(getApp({
            newGame: wasm.Game.new,
            encode: wasm.Game.encode,
            decode: wasm.Game.decode,
        }));
    });
